<template>
  <v-container
    v-if="entity"
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      :heading="heading"
      link="components/simple-tables"
    />

    <base-material-card class="px-5 py-3">
      <template v-slot:heading>
        <v-tabs
          v-model="tab"
          class="mb-4"
          background-color="transparent"
          slider-color="white"
        >
          <v-tab
            v-for="element in filteredTabs"
            :key="element.key"
            :to="element.to"
          >
            {{ element.tab }}
          </v-tab>
        </v-tabs>
      </template>
      <router-view :entity="entity" />
    </base-material-card>
  </v-container>
</template>

<script>

import EntitiesRepository from '@/api/EntitiesRepository'

import { rolesIds } from '@/roles'

export default {
  name: 'EntityShow',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      tabs: [
        {
          key: 1,
          tab: this.$t('c.tabsTitle.orders'),
          to: { name: 'Entity Orders' },
          visibleBy: [rolesIds.admin, rolesIds.stockManager],
        },
        {
          key: 2,
          tab: this.$t('c.tabsTitle.parts'),
          to: { name: 'Entity Parts' },
          visibleBy: [rolesIds.admin, rolesIds.stockManager],
        },
        {
          key: 4,
          tab: this.$t('c.tabsTitle.collaborators'),
          to: { name: 'Entity Collaborators' },
          visibleBy: [rolesIds.admin, rolesIds.stockManager],
        },
        {
          key: 3,
          tab: this.$t('c.tabsTitle.clients'),
          to: { name: 'Entity Clients' },
          visibleBy: [rolesIds.stockManager],
        },
      ],
      tab: 1,
      entity: null,
    }
  },
  computed: {
    heading () {
      return `Entity ${this.entity.name}`
    },
    filteredTabs () {
      return this.tabs.filter(tab => {
        if (!tab.visibleBy) {
          return true
        }
        return tab.visibleBy.includes(this.userRole.id)
      })
    },
    userRole () {
      return this.$store.getters.getUserRole
    },
    isStockManager () {
      return this.$store.getters.isStockManager
    },
  },
  created () {
    EntitiesRepository.show(this.entityId, {})
      .then(({ data }) => {
        this.entity = data
      })
      .catch(() => {

      })
  },
  i18n: {
    messages: {
      en: {
        c: {
          tabsTitle: {
            details: 'details',
            clients: 'clients',
            collaborators: 'collaborators',
            parts: 'parts',
            orders: 'orders',
          },

        },
      },
      it: {
        c: {
          tabsTitle: {
            details: 'dettagli',
            clients: 'clienti',
            collaborators: 'collaboratori',
            parts: 'parti',
            orders: 'ordini',
          },
        },
      },

    },
  },
}
</script>

<style scoped>

</style>
